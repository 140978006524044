import "core-js/modules/es.array.push.js";
import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
Vue.use(VueRouter);
const ifAuthenticated = (to, from, next) => {
  if (!store.getters["user/user"]) {
    next();
    return;
  }
  next({
    name: "Home"
  });
};
const redirectToUserHome = () => {
  const user = store.getters["user/user"];
  if (!user) {
    router.push({
      name: "Login"
    });
  } else {
    if (user.role === "superuser") {
      router.push({
        name: "ClientList"
      });
    } else if (user.role === "warehouseman") {
      router.push({
        name: "Warehouse"
      });
    } else if (user.role === "manager_safety") {
      router.push({
        name: "SafetyAndHealthProtectionList"
      });
    } else {
      router.push({
        name: "EquipmentParkList"
      });
    }
  }
};
const routes = [{
  path: "",
  name: "MainLayout",
  redirect: "/",
  component: () => import("@/layouts/MainLayout"),
  children: [{
    path: "/",
    name: "Home",
    component: () => import("@/views/Home"),
    meta: {
      auth: true,
      roles: ["superuser", "admin", "operational_duty", "head", "lead_engineer", "engineer", "electrician", "electrician_underground", "technician", "locksmith", "warehouseman", "manager_safety"]
    },
    beforeEnter: redirectToUserHome
  }, {
    path: "/login",
    name: "Login",
    component: () => import("@/views/auth/Login"),
    meta: {
      auth: false,
      roles: []
    },
    beforeEnter: ifAuthenticated
  }, {
    path: "/me",
    name: "UserProfile",
    component: () => import("@/views/auth/Profile"),
    meta: {
      auth: true,
      roles: ["admin", "operational_duty", "head", "lead_engineer", "engineer", "electrician", "electrician_underground", "technician", "locksmith"]
    }
  }, {
    path: "/equipment/catalog",
    name: "EquipmentCatalog",
    component: () => import("@/views/equipment/handbook/Catalog"),
    meta: {
      auth: true,
      roles: ["superuser", "admin", "head"]
    }
  }, {
    path: "/equipment/handbook",
    name: "EquipmentHandbook",
    component: () => import("@/views/equipment/handbook/Handbook"),
    meta: {
      auth: true,
      roles: ["superuser", "admin"]
    }
  }, {
    path: "/equipment/handbook/type/:typeId/parameter/:parameterType",
    name: "EquipmentHandbookParameter",
    component: () => import("@/views/equipment/handbook/Parameter"),
    meta: {
      auth: true,
      roles: ["superuser", "admin"]
    }
  }, {
    path: "/equipment/park",
    name: "EquipmentParkList",
    component: () => import("@/views/equipment/park/List"),
    meta: {
      auth: true,
      roles: ["admin", "operational_duty", "head", "lead_engineer", "engineer", "electrician", "electrician_underground", "technician", "locksmith"]
    }
  }, {
    path: "/equipment/park/tree",
    name: "EquipmentParkTree",
    component: () => import("@/views/equipment/park/Tree"),
    meta: {
      auth: true,
      roles: ["admin", "operational_duty", "head", "lead_engineer", "engineer", "electrician", "electrician_underground", "technician", "locksmith"]
    }
  }, {
    path: "/equipment/park/:equipmentId",
    name: "EquipmentProfile",
    component: () => import("@/views/equipment/park/Profile"),
    meta: {
      auth: true,
      roles: ["admin", "operational_duty", "head", "lead_engineer", "engineer", "electrician", "electrician_underground", "technician", "locksmith"]
    }
  }, {
    path: "/company",
    name: "CompanyList",
    component: () => import("@/views/company/List"),
    meta: {
      auth: true,
      roles: ["admin"]
    }
  }, {
    path: "/department",
    name: "DepartmentList",
    component: () => import("@/views/department/List"),
    meta: {
      auth: true,
      roles: ["admin"]
    }
  }, {
    path: "/defect",
    name: "DefectList",
    component: () => import("@/views/defect/List"),
    meta: {
      auth: true,
      roles: ["admin", "operational_duty", "head", "lead_engineer", "engineer", "electrician", "electrician_underground", "technician", "locksmith"]
    }
  }, {
    path: "/defect/:defectId",
    name: "DefectItem",
    component: () => import("@/views/defect/Item"),
    meta: {
      auth: true,
      roles: ["admin", "operational_duty", "head", "lead_engineer", "engineer", "electrician", "electrician_underground", "technician", "locksmith"]
    }
  }, {
    path: "/wpp",
    name: "WPPJournal",
    component: () => import("@/views/wpp/Journal"),
    meta: {
      auth: true,
      roles: ["admin", "operational_duty", "head", "lead_engineer", "engineer", "electrician", "electrician_underground", "technician", "locksmith"]
    }
  }, {
    path: "/wpp/handbook/work",
    name: "WPPHandbookWork",
    component: () => import("@/views/wpp/handbook/Work"),
    meta: {
      auth: true,
      roles: ["admin", "head"]
    }
  }, {
    path: "/wpp/handbook/work-type",
    name: "WPPHandbookWorkType",
    component: () => import("@/views/wpp/handbook/WorkType"),
    meta: {
      auth: true,
      roles: ["admin", "head"]
    }
  }, {
    path: "/wpp/handbook",
    name: "WPPHandbook",
    component: () => import("@/views/wpp/handbook/Handbook"),
    meta: {
      auth: true,
      roles: ["admin", "head"]
    }
  }, {
    path: "/wpp/:equipmentId/calendar",
    name: "WPPEquipmentCalendar",
    component: () => import("@/views/wpp/equipment/Calendar"),
    meta: {
      auth: true,
      roles: ["admin", "operational_duty", "head", "lead_engineer", "engineer", "electrician", "electrician_underground", "technician", "locksmith"]
    }
  }, {
    path: "/object",
    name: "ObjectList",
    component: () => import("@/views/object/List"),
    meta: {
      auth: true,
      roles: ["admin", "head"]
    }
  }, {
    path: "/department/:parent",
    name: "DepartmentChildList",
    component: () => import("@/views/department/List"),
    meta: {
      auth: true,
      roles: ["admin"]
    }
  }, {
    path: "/staff/position",
    name: "PositionList",
    component: () => import("@/views/staff/handbook/Position"),
    meta: {
      auth: true,
      roles: ["admin", "head"]
    }
  }, {
    path: "/staff",
    name: "StaffList",
    component: () => import("@/views/staff/List"),
    meta: {
      auth: true,
      roles: ["admin", "head"]
    }
  }, {
    path: "/staff/work-schedule",
    name: "WorkSchedule",
    component: () => import("@/views/staff/handbook/workSchedule/List"),
    meta: {
      auth: true,
      roles: ["admin", "head"]
    }
  }, {
    path: "/staff/timetable",
    name: "TimeTable",
    component: () => import("@/views/staff/handbook/TimeTable"),
    meta: {
      auth: true,
      roles: ["admin", "head"]
    }
  }, {
    path: "/timetable",
    name: "StaffTimeTable",
    component: () => import("@/views/staff/TimeTable"),
    meta: {
      auth: true,
      roles: ["operational_duty", "lead_engineer", "engineer", "electrician", "electrician_underground", "technician", "locksmith", "warehouseman", "manager_safety"]
    }
  }, {
    path: "/staff/:userId",
    name: "StaffItem",
    component: () => import("@/views/staff/Item"),
    meta: {
      auth: true,
      roles: ["admin", "head"]
    }
  }, {
    path: "/client",
    name: "ClientList",
    component: () => import("@/views/client/List"),
    meta: {
      auth: true,
      roles: ["superuser"]
    }
  }, {
    path: "/regulation/form",
    name: "RegulationForm",
    component: () => import("@/views/regulation/Form"),
    meta: {
      auth: true,
      roles: ["admin", "head"]
    }
  }, {
    path: "/regulation",
    name: "RegulationList",
    component: () => import("@/views/regulation/Regulation"),
    meta: {
      auth: true,
      roles: ["admin", "head"]
    }
  }, {
    path: "/regulation/schedule",
    name: "RegulationSchedule",
    component: () => import("@/views/regulation/Schedule"),
    meta: {
      auth: true,
      roles: ["admin", "head"]
    }
  }, {
    path: "/regulation/history",
    name: "RegulationHistoryList",
    component: () => import("@/views/regulation/History"),
    meta: {
      auth: true,
      roles: ["admin", "head", "operational_duty"]
    }
  }, {
    path: "/regulation/history/:historyId",
    name: "RegulationHistoryItem",
    component: () => import("@/views/regulation/HistoryItem"),
    meta: {
      auth: true,
      roles: ["admin", "head", "operational_duty"]
    }
  }, {
    path: "/work-order",
    name: "WorkOrderJournal",
    component: () => import("@/views/workOrder/Journal"),
    meta: {
      auth: true,
      roles: ["admin", "head"]
    }
  }, {
    path: "/work-order/:equipmentId/calendar",
    name: "WorkOrderEquipmentCalendar",
    component: () => import("@/views/workOrder/equipment/Calendar"),
    meta: {
      auth: true,
      roles: ["admin", "head"]
    }
  }, {
    path: "/work-order/:workOrderId",
    name: "WorkOrderItem",
    component: () => import("@/views/workOrder/Item"),
    meta: {
      auth: true,
      roles: ["admin", "head"]
    }
  }, {
    path: "/work-order/protection-mean",
    name: "WorkOrderProtectionMeanList",
    component: () => import("@/views/workOrder/handbook/ProtectionMean"),
    meta: {
      auth: true,
      roles: ["admin", "head"]
    }
  }, {
    path: "/work-order/security-measure",
    name: "WorkOrderSecurityMeasureList",
    component: () => import("@/views/workOrder/handbook/SecurityMeasure"),
    meta: {
      auth: true,
      roles: ["admin", "head"]
    }
  }, {
    path: "/work-order/customer",
    name: "WorkOrderCustomerList",
    component: () => import("@/views/workOrder/handbook/Customer"),
    meta: {
      auth: true,
      roles: ["admin", "head"]
    }
  }, {
    path: "/aggregate-journal",
    name: "AggregateJournalList",
    component: () => import("@/views/aggregateJournal/List"),
    meta: {
      auth: true,
      roles: ["admin", "head"]
    }
  }, {
    path: "/report/act-of-work-performed",
    name: "ReportActOfWorkPerformed",
    component: () => import("@/views/report/ActOfWorkPerformed"),
    meta: {
      auth: true,
      roles: ["admin"]
    }
  }, {
    path: "/report/type",
    name: "ReportType",
    component: () => import("@/views/report/Type"),
    meta: {
      auth: true,
      roles: ["admin", "head"]
    }
  }, {
    path: "/report/template",
    name: "ReportTemplate",
    component: () => import("@/views/report/template/List"),
    meta: {
      auth: true,
      roles: ["admin", "head"]
    }
  }, {
    path: "/report/template/create",
    name: "ReportTemplateCreate",
    component: () => import("@/views/report/template/Create"),
    meta: {
      auth: true,
      roles: ["admin", "head"]
    }
  }, {
    path: "/report/template/:templateId",
    name: "ReportTemplateShow",
    component: () => import("@/views/report/template/Show"),
    meta: {
      auth: true,
      roles: ["admin", "head"]
    }
  }, {
    path: "/report/template/:templateId/update",
    name: "ReportTemplateUpdate",
    component: () => import("@/views/report/template/Update"),
    meta: {
      auth: true,
      roles: ["admin", "head"]
    }
  }, {
    path: "/report/container",
    name: "ReportContainer",
    component: () => import("@/views/report/container/List"),
    meta: {
      auth: true,
      roles: ["admin", "head", "operational_duty"]
    }
  }, {
    path: "/report/container/create",
    name: "ReportContainerCreate",
    component: () => import("@/views/report/container/Create"),
    meta: {
      auth: true,
      roles: ["admin", "head"]
    }
  }, {
    path: "/report/container/:containerId",
    name: "ReportContainerShow",
    component: () => import("@/views/report/container/Show"),
    meta: {
      auth: true,
      roles: ["admin", "head", "operational_duty"]
    }
  }, {
    path: "/report/container/:containerId/update",
    name: "ReportContainerUpdate",
    component: () => import("@/views/report/container/Update"),
    meta: {
      auth: true,
      roles: ["admin", "head"]
    }
  }, {
    path: "/report/container/:containerId/report/:reportId",
    name: "ReportContainerReportShow",
    component: () => import("@/views/report/container/report/Show"),
    meta: {
      auth: true,
      roles: ["admin", "head", "operational_duty"]
    }
  }, {
    path: "/analytics",
    name: "AnalyticsOverall",
    component: () => import("@/views/analytics/Overall"),
    meta: {
      auth: true,
      roles: ["admin", "head"]
    }
  }, {
    path: "/analytics/ktg",
    name: "AnalyticsKtgOverall",
    component: () => import("@/views/analytics/ktg/Overall"),
    meta: {
      auth: true,
      roles: ["admin", "head"]
    }
  }, {
    path: "/analytics/ktg/area/:areaId",
    name: "AnalyticsKtgArea",
    component: () => import("@/views/analytics/ktg/Area"),
    meta: {
      auth: true,
      roles: ["admin", "head"]
    }
  }, {
    path: "/analytics/defect",
    name: "AnalyticsDefectOverall",
    component: () => import("@/views/analytics/defect/Overall"),
    meta: {
      auth: true,
      roles: ["admin", "head"]
    }
  }, {
    path: "/analytics/defect/area/:areaId",
    name: "AnalyticsDefectArea",
    component: () => import("@/views/analytics/defect/Area"),
    meta: {
      auth: true,
      roles: ["admin", "head"]
    }
  }, {
    path: "/analytics/defect/area/:areaId/equipment/:equipmentId",
    name: "AnalyticsDefectEquipment",
    component: () => import("@/views/analytics/defect/Equipment"),
    meta: {
      auth: true,
      roles: ["admin", "head"]
    }
  }, {
    path: "/analytics/wpp",
    name: "AnalyticsWPPOverall",
    component: () => import("@/views/analytics/wpp/Overall"),
    meta: {
      auth: true,
      roles: ["admin", "head"]
    }
  }, {
    path: "/analytics/wpp/area/:areaId",
    name: "AnalyticsWPPArea",
    component: () => import("@/views/analytics/wpp/Area"),
    meta: {
      auth: true,
      roles: ["admin", "head"]
    }
  }, {
    path: "/analytics/wpp/area/:areaId/equipment/:equipmentId",
    name: "AnalyticsWPPEquipment",
    component: () => import("@/views/analytics/wpp/Equipment"),
    meta: {
      auth: true,
      roles: ["admin", "head"]
    }
  }, {
    path: "/analytics/regulation",
    name: "AnalyticsRegulationOverall",
    component: () => import("@/views/analytics/regulation/Overall"),
    meta: {
      auth: true,
      roles: ["admin", "head"]
    }
  }, {
    path: "/analytics/regulation/area/:areaId",
    name: "AnalyticsRegulationArea",
    component: () => import("@/views/analytics/regulation/Area"),
    meta: {
      auth: true,
      roles: ["admin", "head"]
    }
  },
  /*
  {
    path: "/analytics",
    name: "Analytics",
    component: () => import("@/views/analytics/Analytics"),
    meta: {
      auth: true,
      roles: ["admin", "head"],
    },
  },
  {
    path: "/analytics/equipment-condition",
    name: "AnalyticsEquipmentCondition",
    component: () => import("@/views/analytics/EquipmentCondition"),
    meta: {
      auth: true,
      roles: ["admin", "head"],
    },
  },
  */

  {
    path: "/monitoring",
    name: "MonitoringJournal",
    component: () => import("@/views/monitoring/Journal"),
    meta: {
      auth: true,
      roles: ["admin", "head", "operational_duty"]
    }
  }, {
    path: "/monitoring/:equipmentId/calendar",
    name: "MonitoringEquipmentCalendar",
    component: () => import("@/views/monitoring/equipment/Calendar"),
    meta: {
      auth: true,
      roles: ["admin", "head", "operational_duty"]
    }
  }, {
    path: "/warehouse",
    name: "Warehouse",
    component: () => import("@/views/warehouse/List"),
    meta: {
      auth: true,
      roles: ["admin", "head", "warehouseman"]
    }
  }, {
    path: "/safety/document",
    name: "SafetyDocumentList",
    component: () => import("@/views/safety/handbook/Document"),
    meta: {
      auth: true,
      roles: ["admin", "head"]
    }
  }, {
    path: "/safety/violation",
    name: "SafetyViolationList",
    component: () => import("@/views/safety/violation/List"),
    meta: {
      auth: true,
      roles: ["admin", "head"]
    }
  }, {
    path: "/safety/s-and-h-protection",
    name: "SafetyAndHealthProtectionList",
    component: () => import("@/views/safety/handbook/SafetyAndHealthProtection"),
    meta: {
      auth: true,
      roles: ["admin", "head", "manager_safety"]
    }
  }, {
    path: "/safety/s-and-h-protection-validity",
    name: "SafetyAndHealthProtectionValidity",
    component: () => import("@/views/safety/safetyAndHealthProtection/Validity"),
    meta: {
      auth: true,
      roles: ["admin", "head", "manager_safety"]
    }
  }, {
    path: "/request/handbook-ticket",
    name: "RequestHandbookTicketList",
    component: () => import("@/views/request/ticket/Handbook"),
    meta: {
      auth: true,
      roles: ["admin", "head"]
    }
  }, {
    path: "/request/good",
    name: "RequestGoodList",
    component: () => import("@/views/request/good/List"),
    meta: {
      auth: true,
      roles: ["admin", "head"]
    }
  }, {
    path: "/request/good/handbook",
    name: "RequestGoodHandbook",
    component: () => import("@/views/request/good/Handbook"),
    meta: {
      auth: true,
      roles: ["admin", "head"]
    }
  }, {
    path: "/news",
    name: "NewsList",
    component: () => import("@/views/news/List"),
    meta: {
      auth: true,
      roles: ["admin", "operational_duty", "head", "lead_engineer", "engineer", "electrician", "electrician_underground", "technician", "locksmith", "warehouseman", "manager_safety"]
    }
  }, {
    path: "/news/notification",
    name: "NewsNotificationList",
    component: () => import("@/views/news/notification/List"),
    meta: {
      auth: true,
      roles: ["admin", "operational_duty", "head", "lead_engineer", "engineer", "electrician", "electrician_underground", "technician", "locksmith", "warehouseman", "manager_safety"]
    }
  }, {
    path: "/news/:newsId",
    name: "NewsItem",
    component: () => import("@/views/news/Item"),
    meta: {
      auth: true,
      roles: ["admin", "operational_duty", "head", "lead_engineer", "engineer", "electrician", "electrician_underground", "technician", "locksmith", "warehouseman", "manager_safety"]
    }
  }, {
    path: "*",
    name: "PageNotFound",
    component: () => import("@/views/PageNotFound"),
    meta: {
      auth: false,
      roles: []
    }
  }]
}];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});
router.beforeEach((to, from, next) => {
  if (to.meta.auth) {
    const user = store.getters["user/user"];
    if (user) {
      if (to.meta.roles.includes(user.role)) {
        next();
      } else {
        next({
          name: "PageNotFound"
        });
      }
    } else {
      store.dispatch("user/logoutUser", {}, {
        root: true
      }).then(() => next({
        name: "Login"
      }));
    }
  } else {
    next();
  }
});
export default router;