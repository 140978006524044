import "core-js/modules/es.array.push.js";
import axios from "axios";
import store from "@/store";
import router from "@/router";
import jwt_decode from "jwt-decode";
import constants from "@/common/constants";
const restApi = axios.create({
  baseURL: process.env.VUE_APP_API_URL
});
restApi.interceptors.request.use(config => {
  const token = store.getters["user/token"];
  if (token) {
    config.headers["Authorization"] = `JWT ${token.access}`;
  }
  return config;
}, error => Promise.reject(error));
restApi.interceptors.response.use(response => response, async error => {
  const token = store.getters["user/token"];
  const originalConfig = error.config;
  if (error.response) {
    if (error.response.status === 400) {
      let errorText = [];
      let fields = Object.keys(error.response.data);
      for (let i = 0; i < fields.length; i++) {
        errorText.push("<b>" + fields[i] + "</b>: " + error.response.data[fields[i]].join(", "));
      }
      await store.dispatch("notification/show", {
        text: errorText.join("<br />")
      });
    } else if (error.response.status === 401) {
      if (originalConfig.url !== "/token/auth") {
        if (!originalConfig._retry && "messages" in error.response.data && error.response.data.messages[0]["token_type"] === "access") {
          originalConfig._retry = true;
          try {
            const response = await restApi.post("/token/refresh", {
              refresh: token.refresh
            });
            let tokenData = jwt_decode(response.data.access);
            await store.dispatch("user/setUserData", {
              token: {
                access: response.data.access,
                refresh: response.data.refresh
              },
              user: tokenData.user
            });
            return restApi(originalConfig);
          } catch (_error) {
            store.dispatch("user/logoutUser", {}, {
              root: true
            }).then(() => router.push({
              name: "Login"
            }));
            return Promise.reject(_error);
          }
        } else if ("code" in error.response.data && error.response.data.code === "token_not_valid") {
          store.dispatch("user/logoutUser", {}, {
            root: true
          }).then(() => router.push({
            name: "Login"
          }));
        } else {
          let errorText = [];
          let fields = Object.keys(error.response.data);
          for (let i = 0; i < fields.length; i++) {
            let err = error.response.data[fields[i]];
            if (Array.isArray(err)) {
              err = err.join(", ");
            }
            errorText.push(err);
          }
          await store.dispatch("notification/show", {
            text: errorText.join("<br />")
          });
        }
      }
    } else if (error.response.status === 404) {
      await store.dispatch("notification/show", {
        text: constants.MESSAGE.ERROR.NOT_FOUND
      });
    } else if ([500, 502, 503, 504].includes(error.response.status)) {
      await store.dispatch("notification/show", {
        text: constants.MESSAGE.ERROR.SERVER
      });
    }
  }
  return Promise.reject(error);
});
export default restApi;