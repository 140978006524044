export default {
  namespaced: true,
  state: {
    token: null,
    user: null
  },
  mutations: {
    setToken(state, payload) {
      state.token = payload;
    },
    deleteToken(state) {
      state.token = null;
    },
    setUser(state, payload) {
      state.user = payload;
    },
    deleteUser(state) {
      state.user = null;
    }
  },
  actions: {
    setUserData({
      commit
    }, payload) {
      commit("setUser", payload.user);
      commit("setToken", payload.token);
    },
    logoutUser({
      commit
    }) {
      commit("deleteUser");
      commit("deleteToken");
    }
  },
  getters: {
    user(state) {
      return state.user;
    },
    userRole(state) {
      return state.user ? state.user.role : null;
    },
    userId(state) {
      return state.user ? state.user.id : null;
    },
    userCompanyInfo(state) {
      return state.user ? state.user["company_info"] : null;
    },
    userCompanyId(state) {
      return state.user ? state.user["company_info"] ? state.user["company_info"].id : null : null;
    },
    userAreaId(state) {
      return state.user ? state.user.role === "head" && state.user["area_info"] ? state.user["area_info"].id : null : null;
    },
    token(state) {
      return state.token;
    }
  }
};