export default {
  namespaced: true,
  state: {
    status: true
  },
  mutations: {
    setStatus(state, payload) {
      state.status = payload;
    }
  },
  actions: {
    show({
      commit
    }) {
      commit("setStatus", true);
    },
    hide({
      commit
    }) {
      commit("setStatus", false);
    }
  },
  getters: {
    status(state) {
      return state.status;
    }
  }
};