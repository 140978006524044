import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import user from "@/store/modules/user";
import loading from "@/store/modules/loading";
import notification from "@/store/modules/notification";
import menu from "@/store/modules/menu";
Vue.use(Vuex);
export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    user: user,
    loading: loading,
    notification: notification,
    menu: menu
  }
});