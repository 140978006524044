export default {
  namespaced: true,
  state: {
    status: false,
    title: null,
    text: "",
    type: null
  },
  mutations: {
    update(state, payload) {
      state.status = payload.status;
      state.title = payload.title;
      state.text = payload.text;
      state.type = payload.type;
    }
  },
  actions: {
    show({
      commit
    }, payload) {
      let text = "";
      let type = "error";
      if (typeof payload === "object") {
        text = payload.text;
        if (["error", "success"].includes(payload.type)) {
          type = payload.type;
        }
      } else {
        text = payload;
      }
      commit("update", {
        status: true,
        title: payload.title,
        text: text,
        type: type
      });
    },
    hide({
      commit
    }) {
      commit("update", {
        status: false,
        title: null,
        text: "",
        type: null
      });
    }
  },
  getters: {
    status(state) {
      return state.status;
    },
    title(state) {
      return state.title;
    },
    text(state) {
      return state.text;
    },
    type(state) {
      return state.type;
    }
  }
};